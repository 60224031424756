<template>
  <div class="notfound-container">
    <img src="@/assets/images/404.png" alt="" />
    <h2>噢！找不到页面。</h2>
    <router-link to="/"><el-button type="primary" block style="margin-top: 0.5rem" class="btns"
        >返回首页</el-button
      ></router-link
    >
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.notfound-container {
  width: 100%;
  padding-top: 80px;
  img {
    display: block;
    width: 560px;
    margin: 0 auto;
  }
  h2 {
    color: #333;
    font-size: 28px;
    margin-top: 35px;
    margin-bottom: 40px;
  }
  .btns {
    font-size: 18px;
    text-align: center;
    color: #fff;
    background: #d71a18;
    width: 150px;
    height: 56px;
    display: inline-block;
    text-align: center;
    line-height: 56px;
    padding: 0;
    border-radius: 30px;
  }
}
</style>